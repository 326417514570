<template>
  <div class="particulars">
    <CompanyHeader titleurl="contact" v-if="!isfromoa" />
    <div class="particulars-center">
      <div class="product">
        <div class="product-content">
          <div class="contact">
            联系电话：{{ cmpinfo.companyTel
            }}<span class="contact_add">联系地址：{{ cmpinfo.address }}</span>
          </div>
          <div id="contactamaps5" v-if="isamap">
            <el-amap
              vid="amapcontactamaps"
              :center="center"
              :zoom="zoom"
              class="amap-demo"
            >
              <el-amap-marker
                v-for="(marker, index) in markers"
                :position="marker"
                :key="index"
              ></el-amap-marker>
            </el-amap>
          </div>
        </div>
      </div>
    </div>

    <Footer v-if="!isfromoa" />
  </div>
</template>
<script>
import { companygetcompanyinfo } from '@/api/company.js';
import CompanyHeader from '@/components/companyheader.vue';
import Footer from '@/components/footer.vue';
export default {
  name: 'App',
  components: {
    CompanyHeader,
    Footer
  },
  data() {
    return {
      center: [113.768678, 34.767936],
      markers: [[113.768678, 34.767936]],
      zoom: 15,
      isamap: true,
      isfromoa: false,
      productlist: [],
      cid: '',
      cmpinfo: {},
      type: '',
      pageIndex: 1,
      pageSize: 12
    };
  },
  methods: {
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then(res => {
        if (res.data.code == 1) {
          this.cmpinfo = res.data.result;
          if (
            this.cmpinfo.lnglatAmap != null &&
            this.cmpinfo.lnglatAmap.length > 0
          ) {
            this.center = this.cmpinfo.lnglatAmap.split(',');
            this.markers = [this.center];
          } else {
            this.isamap = false;
          }
        }
      });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.oncompanygetcompanyinfo();
  },
  created() {
    //console.warn('query.id', this.$route.query);
    if (this.$route.query.id === '409') {
      //言天下
      this.$router.push({
        path: `/company/ytx_contact`,
        query: { id: this.$route.query.id }
      });
    }
  }
};
</script>
<style scoped>
.contact {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.contact .contact_add {
  margin-left: 30px;
}
#contactamaps5 {
  margin: 10px;
  width: 1140px;
  height: 300px;
}
.particulars {
  width: 100%;
  background: #fff;
}

.particulars-center {
  width: 100%;
  min-width: 1280px;
}
.product {
  max-width: 1200px;
  margin: 0 auto;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
</style>
